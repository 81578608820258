/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";


const SEO = ({ title, description, keywords, image, meta }) => {

    const author = `Happy Indra Wijaya`;

    return (
        <Helmet
            htmlAttributes={{lang: `en`}}
            title={title}
            defaultTitle={`Happy Indra Wijaya`}
            meta={[
                {
                    name: `author`,
                    content: author,
                },
                {
                    name: `description`,
                    content: description,
                },
                {
                    name: `keywords`,
                    content: keywords.join,
                },

                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: image,
                },

                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ].concat(meta)}
        />
    )
}
export default SEO;

SEO.defaultProps = {
    title: `Happy Indra Wijaya`,
    description: `a software engineer`,
    keywords: [],
    image: `/images/hiwijaya.png`, 
    meta: [],
}